import cmlogo from "../../images/cm/cm-logo.png"
import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Sidebar from "./Sidebar";
import { Link } from "react-scroll";
import { useWeb3React } from '@web3-react/core'
import WalletButton from "../WalletConnect/WalletConnect"
import logo from "../../images/logo192.png";
import twitter from "../../images/twitter.svg";
import telegram from "../../images/telegram.png";
import social from "../../images/discord.svg";
import opensea from "../../images/opensea.svg";
// import "./style.css";
import linkedin from "../../images/linkedin.png";

const Wrapper = styled.div`
  width: 100%;
  /* left: 0; */
  /* top: 0; */
  /* position: fixed; */
  background: black;
  .visit-us {
    margin: 0 0 0 50px;
    @media only screen and (max-width: 767px) {
      margin: 0 0 0 25px;
      display: flex;
    }
  }

  .logo-connect {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .navbars {
    padding: 0 80px;
  }
  .logo {
    color: var(--text-color);
    font-size: 65px;
  }
  a.home {
    /* text-decoration: none !important; */
  }
  a {
    /* text-decoration: none; */
    /* text-transform: uppercase; */
  }
  .menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu-container a,
  .menu-container p, 
  .menu-container span {
    color: var(--text-color);
    text-decoration: none;
    font-size: 16px;
    font-family: "PoppinsLight", sans-serif !important;
    padding: 10px;
    /* text-transform: uppercase; */
    font-weight: 700;
  }
  .menu-container span {
    margin-right: 30px;
  }
  .hamburger {
    display: none;
    cursor: pointer;
  }
  .wallet_button {
    background: #fff;
    color: #000;
    /* margin-top: 15px; */
    font-family: "PoppinsLight", sans-serif;
    font-weight:700;
    overflow: hidden;
    display: inline-block;
    position: relative;
    padding: 12px 20px 12px;
    min-width: 120px;
    border: 2px solid transparent;
    min-height: 44px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(136 144 195 / 20%), 0 5px 15px 0 rgb(37 44 97 / 15%);
    line-height: 1.2;
    font-weight: 700;
  }
  
  // .wallet_button:hover {background-color: #6891ed}
  .mobile-it {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: flex;
  }
  .logo {
    margin: -10px 0 0;
  }
  @media only screen and (max-width: 991px) {
    .mobile-it {
      justify-content: space-between;
      /* padding-right: 0; */
    }
    .desktop-only {
      display: none;
    }
    .mobile-only {
      display: flex;
    }
    .wallet_button {
      /* font-size: 14px !important; */
      margin: 5px 0 5px 120px;
      padding: 10px;
    }
    .navbars {
      padding: 0 50px;
    }
    .logo {
      font-size: 50px;
    }

    .menu-container a,
    .menu-container p {
      font-size: 16px;
      padding: 10px 10px;
      font-style: italic;
    }
  }
  @media only screen and (max-width: 953px) {
    .menu-container a,
    .menu-container p {
      font-size: 15px;
      padding: 10px 5px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 767px) {
    position: relative;
    .navbars {
      padding: 0 30px;
    }
    .menu-container {
      /* flex-direction: column-reverse; */
    }
    .hamburger {
      display: block;
      margin-right: 10px;
    }
  }
`;

const Navbar = props => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar((prev) => !prev);
  };

  const menuItem = [
    // {
    //   text: "My collection",
    //   url: "mycollection",
    //   ischecked: false,
    // },
    // {
    //   text: "Market (Coming soon)",
    //   url: "",
    //   ischecked: false,
    // },
    // {
    //   text: "How It Works",
    //   url: "howitworks",
    //   ischecked: false,
    // },
    // {
    //   text: "Roadmap",
    //   url: "roadmap",
    //   ischecked: false,
    // },
    // {
    //   text: "Team",
    //   url: "team",
    //   ischecked: false,
    // },
    {
      text: "Connect",
      url: "connect",
      ischecked: true,
    },
  ];

  const { account } = useWeb3React()

  return (
    <Wrapper className="">
      <div className="py-1">
        {/* <Row> */}
        <Col
          // xs={6}
          // md={10}
          className="mobile-it"
        >
          <div className="menu-container">
            {/* <span>healthcare heroes nft</span> */}
            <a className="desktop-only" href="https://www.collectingmeta.com" target="_self">
              <img src={cmlogo} alt="" className="logo" width="85px" height="85px" />
            </a>
            <a className="mobile-only" href="http://www.collectingmeta.com" target="_self">
              <img src={cmlogo} alt="" className="logo" width="55px" height="55px" />
            </a>
            <a href="https://collectingmeta.com" target="_blank" >COLLECTING META</a> 
            {/* <p className="mb-0">|</p> */}
            {/* <a href="https://collectmeta.com/claim" target="_self" >CLAIM</a> */}
            {/* <Link to="mycollection">
                <NavLink to="/mycollection">my bunnypunks</NavLink>
              </Link> */}
            {/* <div className="logo-connect my-2">
                <img src={logo} alt="logo" width="380px" className="mb-2" /> */}
            {/* </div> */}
            {/* <Link to="art">
              <NavLink to="#art">Art</NavLink>
            </Link>
            <Link to="mission">
              <NavLink to="#mission">mission</NavLink>
            </Link>
            <Link to="roadmap">
              <NavLink to="#roadmap">roadmap</NavLink>
            </Link>
            <Link to="aboutus">
              <NavLink to="#aboutus">Team</NavLink>
            </Link>
            <Link to="faq">
              <NavLink to="#faq">FAQ</NavLink>
            </Link>
            <Link to="/support">
              <NavLink to="/support">Support</NavLink>
            </Link> */}
            <div className="visit-us">
              <a href="https://twitter.com/collectingmeta" target="_blank"><img width="35px" src={twitter} alt="twitter" /></a>
              <a href="https://discord.com/invite/uq9B8nepNb" target="_blank"><img width="35px" src={social} alt="discord" /></a>
              <a href="https://opensea.io/collection/collectingmeta" target="_blank"><img width="35px" src={opensea} alt="opensea" /></a>
              {/* <a href="https://www.linkedin.com/in/healthcare-heroesnft-793ba1231/" target="_blank"><img width="35px" src={linkedin} alt="linkedin" /></a> */}
              {/* <a href="https://discord.gg/gDWAmsdQB5" target="_blank"><img width="35px" src={social} alt="discord" /></a> */}
            </div>
          </div>
          {/* <WalletButton /> */}
          {/* <GiHamburgerMenu
            className="hamburger"
            size="30"
            color="var(--text-color)"
            onClick={showSidebar}
          /> */}
        </Col>
        {/* </Row> */}
        {/* {sidebar && <Sidebar menuItem={menuItem} showSidebar={showSidebar} />} */}
      </div>
    </Wrapper>
  );
};

export default Navbar;
