import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "./Home/Home"
import Claim from "./Components/Claim/Claim"
import Mint from "./Components/Mint/Mint"

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Mint />
        </Route>
        {/* <Route exact path="/claim">
          <Claim />
        </Route> */}
      </Switch>
    </>
  );
}

export default App;
