import React, { useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useState } from "react";
import Web3 from "web3";
import { ABI, contractAddress, rpcAddress } from "../../ABI/variables";
import { useWeb3React } from '@web3-react/core'
import rarity from "../../images/icon.png";
import traits from "../../images/icon.png";
import image1 from "../../images/bears.gif";
import hh from "../../images/h_h.png";
import hhbanner from "../../images/hhbanner.jpeg";
import WalletButton from "../WalletConnect/WalletConnect"
import HowItWorks from "../HowItWork/HowItWorks";
import Hero from "../Hero/Hero";
import Navbar from "../Navbar/Navbar";
import MerkleTree from "merkletreejs";
import keccak256 from "keccak256";
import { BiLoaderAlt } from "react-icons/bi";

import cmcentral from "../../images/cm/cm-central.png"
import cmgold from "../../images/cm/cm-gold.png"
import cmlogo from "../../images/cm/cm-logo-edit.png"
import cmown from "../../images/cm/cm-own.png"
import cmprereveal from "../../images/cm/cm-prereveal.png"
import cmroadmap from "../../images/cm/cm-roadmap.png"
import cmroyal from "../../images/cm/cm-royal.png"

const Wrapper = styled.div`
  width: 100vw;
  // text-transform: uppercase;
  padding: 0 0 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  box-sizing: border-box;
  background: black;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  .aboutthat {
    max-width: 1200px;
    display: block;
    margin: 0 auto;
    text-align: justify;
  }
  .small-font {
      font-size: 14px !important;
      font-style: italic;
  }
  .link-dark {
    color: #fff;
  }
  .button a {
    color: white;
    text-decoration: none;
  }
  .imagebanner {
    img {
      margin: 50px auto 25px;
    display: flex;
    @media only screen and (max-width: 767px) {
      margin: 20px auto;
    }
    }
  }
  .wrap {
    max-width: 1200px;
  }
  .banner {
    max-width: 100%;
  }
  .hero-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .highlight {
    font-size: 50px;
    color: #fff;
  }
  .main-gif {
    max-width: 400px;
    border: 4px solid #fff;
    @media only screen and (max-width: 767px) {
      /* max-width: 80%; */
    }
  }
  .highlight-text {
    color:#fff;
  }
  .mmint {
    margin: 50px auto 0;
    text-align: center;
    h2 {
      font-style: italic;
      font-size: 50px !important;
      margin-bottom: 15px;
      @media only screen and (max-width: 991px) {
        font-size: 35px !important;
        /* text-align: left; */
      }
    }
    p {
      letter-spacing: -1px;
      @media only screen and (max-width: 991px) {
        font-size: 20px;
      }
    }
  }
  .r-title {
    color: #fff;
    font-size:65px;
    font-weight:700;
    padding-top: 80px !important;
    text-align: center;
    @media only screen and (max-width: 991px) {
      padding-top: 30px !important;
    }
  }

  .title {
    font-family: "PoppinsLight", sans-serif;
    font-size: 130px;
    font-weight: 700;
  }
  .hero-gif {
    padding: 40px 0 0;
    text-align: center;
  }
  .subtitle {
    margin-top: 30px;
    font-size: 22px;
    text-transform: none;
    color: white;
    /* text-align: left; */
    letter-spacing: -1px;
    text-align: justify;
    @media only screen and (max-width: 991px) {
      margin-top: 0;
    }
  }
  .contain-it {
      margin: 20px auto 0;
      h1, h6 {
          text-align: center;
          margin: 0 auto 40px !important;
      }
      h6 {
        font-size: 20px;
      }
      @media only screen and (max-width: 767px) {
          margin: 25px auto;
          h1, h6 {
            margin: 0 auto 20px !important;
          }
      }
  }
  .hero-footer {
    color: #fff;
    text-align: center;
    padding: 50px 0;
    font-weight: 700;
    margin: 0 auto;
    @media only screen and (max-width: 767px) {
        border-bottom: none;
      }
}
  .mintsoon {
    text-transform: uppercase;
    display: inline-block;
    padding: 15px 20px;
    font-family: "PoppinsLight", sans-serif;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #710A0B;
    border: none;
    // border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 12px;
    border-radius: 35px;
  }
  .hero-footer p,
  .hero-footer span
   {
    margin-top: 10px;
    font-size: 20px;
  }
  .hero-footer h2 {
    font-size: 70px;
    font-weight:700;
    color: white;
  }
  .borders {
    border: 5px solid #000;
    padding: 4px 15px;
  }
  .borders-left {
    border: 5px solid #000;
    cursor: pointer;
    padding: 4px 15px;
  }
  .borders-right {
    border: 5px solid #000;
    border-left: 0;
    cursor: pointer;
    padding: 4px 15px;
  }
  .icon img {
    width: 100px;
  }
  .para {
      font-size: 20px;
      /* font-weight: 700; */
  }
  .button {
    border: none;
    background: none;
    margin-top: 15px;
  }
  .button a, .wallet_button, #dropdown, .mint-it .button {
    background: #fff;
    color: black;
    font-family: "PoppinsLight", sans-serif;
    font-weight:700;
    overflow: hidden;
    display: inline-block;
    position: relative;
    padding: 12px 20px 12px;
    min-width: 120px;
    border: 2px solid transparent;
    min-height: 44px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(136 144 195 / 20%), 0 5px 15px 0 rgb(37 44 97 / 15%);
    line-height: 1.2;
    font-weight: 700;

  }
  .hero-footer.claim {
    max-width: 1000px;
    margin-top: 40px;
    padding: 20px 15px 40px;
  }
  #dropdown {
    padding: 10px 20px 10.5px;
    margin-right: 25px;
    min-width: auto;
  }
  .wallet_button {
    margin: 10px auto;
    // width: 250px;
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    /* text-transform: uppercase; */
  }
  .italic {
    font-style: italic;
  }
  .mint-it {
    display: flex;
  }
  .hero-div img.logo-mobile {
    display: none;
  }
  @media only screen and (max-width: 991px) {
    padding: 0px 10px;
    .icon img {
    width: 60px;
  }
  .highlight {
    font-size: 35px;
  }
  .hero-footer {
    // border-radius: 30px;
    padding: 15px;
  }
  .hero-footer h4{
      font-size:30px;
  }
  .borders {
    padding: 4px 4px;
  }
  .borders-left {
    padding: 8px 4px;
  }
  .borders-right {
    padding: 8px 4px;
  }
  .buttons{
      // display:flex;
  }
  .button, .wallet_button {
      // font-size:12px;
      // padding:12px 10px;
  }
  }
  @media only screen and (max-width: 767px) {
    .subtitle {
      font-size: 20px;
    }
    .title {
      font-size: 80px;
    }
    .hero-footer h2 {
      font-size: 70px;
    }  
    .hero-div {
      padding: 0 0 30px 0 !important;
    }
    .hero-div img {
      // display: inline-block; 
      width: 100%;
      // height: auto;
      }
    .hero-gif {
      padding-top: 0;
      background: none;
    }
    .main-gif {
      /* margin: 0 auto 40px; */
      width: 100%;
     }
    .hero-div img.logo-mobile {
      // display: block;
      // margin: 0 auto 20px;
    }
  }
`;

const Select = styled.select`
  // padding: 10px 0;
`

const Mint = () => {
  const [num, setNum] = useState('Connect wallet to view total');
  const [mint, setMint] = useState(1);
  const [successfulPurchase, setsuccessfulPurchase] = useState(false)
  const [hasError, setHasError] = useState('')
  const [transactionHash, setTransactionHash] = useState('')
  const { account, library } = useWeb3React();
  const [isLoading, setIsLoading] = useState(false)
  const [currentCost, setCurrentCost] = useState()
  const [passBalance, setPassBalance] = useState()

  const web3 = new Web3(
    new Web3.providers.HttpProvider(
      // `${process.env.REACT_APP_NETWORK_URL}`
      rpcAddress
    )
  );
  const ourContract = new web3.eth.Contract(ABI, contractAddress);

  useEffect(() => {
    ourContract.methods.totalSupply().call()
      .then((resp) => {
        setNum(resp);
      })
      .catch(function (error) {
        setNum('?')
      })
  }, [num])

  useEffect(() => {
    if (account) {
      ourContract.methods.getPassBalance(account).call()
        .then((resp) => {
          setPassBalance(Number(resp))
        })
        .catch(function (error) {
          setNum('?')
        })
    }
  }, [account, passBalance])

  useEffect(() => {
    ourContract.methods.getCurrentCost().call()
      .then((resp) => {
        setCurrentCost(resp)
      })
      .catch(function (error) {
        setCurrentCost('?')
      })
  }, [currentCost])

  function handleChange(event) {
    setMint(event.target.value)
  }

  const passHolderMint = async () => {
    setHasError('')
    setsuccessfulPurchase(false);
    setIsLoading(true)
    if (account) {
      const web3 = new Web3(library.provider);
      const ourContract = new web3.eth.Contract(ABI, contractAddress);
      ourContract.methods.passHolderMint(mint)
        .send({
          from: account,
          value: currentCost * mint,
        })
        .on("receipt", function (receipt) {
          setsuccessfulPurchase(true);
          setTransactionHash(receipt.transactionHash)
          setHasError('')
          setIsLoading(false)
          ourContract.methods.totalSupply().call()
            .then((resp) => {
              setNum(resp);
            })
            .catch(function (error) {
              setNum('?')
            })
          ourContract.methods.getCurrentCost().call()
            .then((resp) => {
              setCurrentCost(resp)
            })
            .catch(function (error) {
              setCurrentCost('?')
            })
        })
        .on("error", function (error) {
          setHasError("Unsuccessful purchase.")
          setsuccessfulPurchase(false)
          setIsLoading(false)
        });
    } else {
    }
  }

  const publicSaleMint = async () => {
    setHasError('')
    setsuccessfulPurchase(false);
    setIsLoading(true)
    if (account) {
      const web3 = new Web3(library.provider);
      const ourContract = new web3.eth.Contract(ABI, contractAddress);
      ourContract.methods.mint(mint)
        .send({
          from: account,
          value: currentCost * mint,
        })
        .on("receipt", function (receipt) {
          setsuccessfulPurchase(true);
          setTransactionHash(receipt.transactionHash)
          setHasError('')
          setIsLoading(false)
          ourContract.methods.totalSupply().call()
            .then((resp) => {
              setNum(resp);
            })
            .catch(function (error) {
              setNum('?')
            })
          ourContract.methods.getCurrentCost().call()
            .then((resp) => {
              setCurrentCost(resp)
            })
            .catch(function (error) {
              setCurrentCost('?')
            })
        })
        .on("error", function (error) {
          setHasError("Unsuccessful purchase.")
          setsuccessfulPurchase(false)
          setIsLoading(false)
        });
    } else {
    }
  }

  return (
    <>
      <Navbar />
      <Wrapper id="mint-wrapper">
        <Container fluid className="contain-it">
          {/* <h1>COLLECTING META</h1> */}
          <img src={cmlogo} alt="" style={{ margin: "0 auto 40px", display: "flex", maxWidth: "100%" }} className="" />
          <h6>Collecting Meta will be the largest metaverse community wallet in the entire NFT industry.
            <br /><br />
            We are empowering our community with various metaverse platforms and the utility within them.
          </h6>
          <img src={cmown} alt="" style={{ margin: "0 auto 40px", display: "flex", maxWidth: "100%" }} className="" />
          <div className="around">
            {/* un comment */}
            <Col
              xs={12}
              md={7}>
              <div className="hero-footer">
                <h3>MINT YOUR PASSES</h3>
                <br />
                <p>Total minted: {num} / 18,000</p>
                <p>Mint price: {currentCost && web3.utils.fromWei(currentCost, "ether")} ETH</p>
                <Row className="middeling">
                  <Col md={5} lg={6} className="">
                    <WalletButton />
                    {account ?
                      <Row className="mint-it">
                        <Col className="px-2">

                          {/* {
                            passBalance > 0 ?
                              <>
                                <p>The connected wallet holds {passBalance} Skuxxverse Pass, and is eligible for pre mint.</p>
                                <Select name="dropdown" id="dropdown" onChange={handleChange}>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                </Select>
                                <button
                                  className="button"
                                  onClick={passHolderMint}
                                >Mint</button>
                              </> :
                              <p>The connected wallet holds {passBalance} Skuxxverse Pass, and is not eligible for pre mint.</p>
                          } */}

                          <Select name="dropdown" id="dropdown" onChange={handleChange}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                      </Select>
                          <button
                        className="button"
                      onClick={publicSaleMint}
                      >Mint</button>

                        </Col>
                      </Row> : ''}
                  </Col>

                  {isLoading ?
                    <div className="is-loading">
                      <BiLoaderAlt
                        size={25}
                        className="block animate-spin"
                      />
                      <p className="small-font">Transaction in progress...</p>
                    </div>
                    : ''}

                </Row>
                {hasError ? <p>{hasError}</p> : ''}
                {successfulPurchase ? <p>Success! <a className="link-dark" href={`https://www.etherscan.io/tx/${transactionHash}`} target="_blank">Click to view your transaction.</a></p> : ''}
              </div>
            </Col>

            <Col
              xs={12}
              md={3}>
              <div className="roadmap">
                <img src={cmroyal} alt="roadmap" width="300px" className="mb-2" />
                <img src={cmgold} alt="roadmap" width="300px" className="mb-2" />
                <img src={cmcentral} alt="roadmap" width="300px" className="mb-2" />
              </div>
            </Col>
          </div>
          <div id="art" className="" style={{ marginTop: "40px" }}>
            <img src={cmroadmap} alt="" style={{ maxWidth: "1200px" }} className="" />
          </div>
          {/* <p className="py-5 text-center para"><a className="link" href={`https://www.etherscan.io/token/${contractAddress}`} target="_blank">Verified smart contract</a></p> */}
        </Container>
      </Wrapper>
    </>
  );
};

export default Mint;
