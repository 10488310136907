import { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core'
import { ConnectorId, useWalletModal } from '@pantherswap-libs/uikit'
import { injected, bsc, walletconnect } from '../../connectors'
import Web3 from "web3";

const WalletConnect = () => {
    const { account, activate, deactivate } = useWeb3React()
    const web3 = new Web3(Web3.givenProvider);
    const [networkIsCorrect, setNetWorkIsCorrect] = useState(false)

    useEffect(async () => {
        signIn()
    }, [])


    async function signIn() {
        window.ethereum.enable()
            .then(function (accounts) {
                web3.eth.getChainId().then((chainId) => {
                    // console.log('chainId', chainId);
                    if (chainId != 1) { // 4 testnet 1 mainnet
                        // alert("You are on the wrong network. Change network to Ethereum.");
                    } else {
                        let wallet = accounts[0]
                        // console.log('wallet', wallet);
                        handleLogin(wallet)
                    }
                });
            })

        const chainId = 1
        if (window.ethereum.networkVersion !== chainId) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: web3.utils.toHex(chainId) }]
                });
                setNetWorkIsCorrect(true)
            }
            catch (err) {
                setNetWorkIsCorrect(false)
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainName: 'Ethereum Network',
                                chainId: web3.utils.toHex(chainId),
                                nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'ETH' },
                                rpcUrls: ['https://mainnet.infura.io/v3/']
                            }
                        ]
                    });
                }
            }
        }
    }

    const handleLogin = (connectorId) => {
        if (connectorId === 'walletconnect') {
            return activate(walletconnect)
        }
        else if (connectorId === 'bsc') {
            return activate(bsc)
        }
        return activate(injected)
    }

    const { onPresentConnectModal } = useWalletModal(handleLogin, deactivate, account)

    const { onPresentAccountModal } = useWalletModal(handleLogin, deactivate, account)

    return (
        !account ? <>
            <button type="button" className="wallet_button not-connected" onClick={onPresentConnectModal}>Connect Wallet</button>
            {!networkIsCorrect ?
                <p type="button" className="network-check" onClick={signIn}>You are on the wrong network. Click to switch to Ethereum network.</p>
                :
                ""}
        </>
            : <button type="button" className="wallet_button connected" onClick={onPresentAccountModal}>Wallet Connected</button>
    )
}

export default WalletConnect