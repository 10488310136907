import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import image1 from "../../images/1.png";
import image2 from "../../images/2.png";
import image3 from "../../images/3.png";
import image4 from "../../images/4.png";
import banner from "../../images/hh-banner.png";
import banner2 from "../../images/mobile-banner.png";


const Image = styled.img`
  width: 80%;
  margin: 90px auto 0;
  display: block;
  /* margin-left: calc(50% - 50vw); */
  /* margin-right: calc(50% - 50vw); */
  /* width: 100vw; */
  @media only screen and (max-width: 768px) {
      display: none;
  }
`

const Image2 = styled.img`
  width: 100%;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 100vw;
  display: none;
  @media only screen and (max-width: 768px) {
      display: block;
  }
`

const Hero = () => {
  return (
    <>
      <Image src={banner} alt="healthcare heroes nft" className="banner" />
      <Image2 src={banner2} alt="healthcare heroes nft" className="banner" />
    </>
  );
};

export default Hero;