import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { useWeb3React } from '@web3-react/core'
import { ConnectorId, useWalletModal } from '@pantherswap-libs/uikit'
import { injected, walletconnect } from '../../connectors'
import WalletButton from "../WalletConnect/WalletConnect"
import twitter from "../../images/twitter.png";
import telegram from "../../images/telegram.png";
import social from "../../images/social.png";
import instagram from "../../images/instagram.png";
import linkedin from "../../images/linkedin.png";

const Wrapper = styled.div`
  padding-bottom: 40px;
  height: 100vh;
  .sidebar-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* background: var(--blue); */
    padding-bottom: 35px;
  }
  .sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sidebar a {
    text-decoration: none;
    color: var(--text-color);
    font-size: 18px;
    margin: 8px 0;
    padding: 10px 20px;
    font-weight: 700;
  }
  .close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 10px 10px 0px;
    cursor: pointer;
    opacity: 0;
  }
  .wallet_connect {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
`;

const Sidebar = (props) => {
  const { menuItem, showSidebar } = props;

  return (
    <Wrapper>
      <div className="sidebar-container">
        <div className="close">
          <IoMdClose color="#fff" size={30} onClick={showSidebar} />
        </div>
        <div className="sidebar">
          <br />
          {/* <a href="http://www.healthcarenft.io/#art" target="_self" >Art</a>
          <a href="http://www.healthcarenft.io/#mission" target="_self" >mission</a>
          <a href="http://www.healthcarenft.io/#roadmap" target="_self" >roadmap</a>
          <a href="http://www.healthcarenft.io/#aboutus" target="_self" >Team</a>
          <a href="http://www.healthcarenft.io/#faq" target="_self" >FAQ</a>
          <a href="http://www.healthcarenft.io/support" target="_self" >Support</a> */}
          <br />
          <div className="visit-us">
            {/* <a href="https://twitter.com/IceverseDAO" target="_blank"><img width="35px" src={twitter} alt="twitter" /></a>
            <a href="https://discord.gg/iceverse" target="_blank"><img width="35px" src={social} alt="discord" /></a> */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Sidebar;
